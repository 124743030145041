<template>
  <div>
    <dashboard-page-title :showFilter="false" :showSearch="false" :showExportation="false" :showAddBtn="false">اضافة اوراق جديدة</dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <b-form class="position-relative form-top w-50 " @submit.prevent="handleSubmit(addRequired)">
        <b-tabs content-class="mt-3">
          <b-tab v-for="(lang, key) in translations" :key="key" :title="lang.code" >
            <input-form :label="' الاسم -' + lang.code " placeholder="الاسم" v-model="lang.name" :validate="lang.is_default ? 'required':'' " />
            <input name="translations[lang]" :value="lang.code" type="hidden"/>
          </b-tab>
        </b-tabs>
        <b-button variant="primary" :disabled="invalid" type="submit"><span  class="font-size-18 px-5">اضافة</span></b-button>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import transportationServices from '@/modules/transportation/services/transportation'
import getLanguages from '@/mixins/getLanguages'

export default {
  name: 'addRequired',
  mixins: [getLanguages],
  data () {
    return {
      loadingLogo: 0,
      translatedColumns: ['name', 'title'],
      translations: [],
      type: '',
      status: '1'
    }
  },
  methods: {
    getTypeData () {
      this.getAllLanguages.forEach(data => {
        const translation = {
          lang: data.code,
          ...data
        }
        this.translatedColumns.forEach(key => {
          [{ code: 'en', name: 'aa', title: 'title' }, { code: 'ar', name: 'اى حاجه', title: 'عنوان' }].forEach(item => {
            if (translation.code === item.code) {
              Object.keys(item).forEach(keyInObj => {
                if (key === keyInObj) {
                  translation[key] = item[keyInObj]
                } else {
                  translation[key] = ''
                }
              })
            }
          })
        })
        this.translations.push(translation)
      })
    },
    addRequired () {
      const finalTranslations = this.translations.filter(translation => this.translatedColumns.some(column => translation[column]))
      transportationServices.addRequiredDocument({
        type: this.$route.query.type,
        status: this.status,
        translations: finalTranslations
      }).then(res => {
        core.showSnackbar('success', res.data.message)
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.getAllLanguages.forEach(data => {
      const translation = {
        lang: data.code,
        ...data
      }
      this.translatedColumns.forEach(data => {
        translation[data] = null
      })
      this.translations.push(translation)
    })
  }
}
</script>
